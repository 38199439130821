import post from "./components/post/index.vue"
import {mapActions, mapGetters} from "vuex";
import VueHead from 'vue-head'
import Vue from 'vue'

Vue.use(VueHead)

export default {
  name: "posts",
  components: {
    post
  },
  data() {
    return {
      params: {
        title: '',
        description: '',
        keywords: '',
        image: ''
      },
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.title !== '' ? this.params.title : '',
        separator: ' ',
      }
    },
    meta: function () {
      return [
        // {name: 'description', content: this.params.description},
        {n: 'keywords', content: this.params.keywords, id: 'keywords'},
        {p: 'og:title', content: this.params.title, id: 'og:title'},
        {p: 'og:description', content: this.params.description, id: 'og:description'},
        {n: 'title', content: this.params.title , id: 'title'},
        {n: 'description', content: this.params.description , id: 'description'},
        {p: 'og:image', content: this.params.image, id: 'og:image'},
        // {name: 'image', content: this.params.image},
      ]
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' },
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + '/ru' + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }
  },
  created() {
    this.fetchArticle(this.$route.params.post)
    this.fetchSimilarArticles(this.$route.params.post)
  },
  watch: {
    '$route' (newVal) {
      if (newVal) {
        this.fetchArticle(this.$route.params.post)
      }
    },

    article: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setMeta()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      article: `pages/oneArticle`,
      similarArticles: `pages/similarArticles`,
    }),
  },
  methods: {
    ...mapActions({
      fetchArticle: `pages/GET_ONE_ARTICLE`,
      fetchSimilarArticles: `pages/GET_SIMILAR_ARTICLES`,
    }),

    setMeta() {
      this.params.title = this.article.meta_title !== '' ? this.article.meta_title : 'ICHIBAN STORE',
        this.params.description = this.article.meta_description !== '' ? this.article.meta_description : 'ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE',
        this.params.keywords = this.article.meta_keywords,
        this.params.image = this.article.image !== '' ? this.article.image : 'https://ichibanstore.jp/logo.png'
      this.$emit('updateHead')
    },
  },

}